// eslint-disable-next-line max-classes-per-file
export class NotEnoughContentForExamError extends Error {
  readonly isNotEnoughContentForExamError: boolean = true;

  override name: string = NotEnoughContentForExamError.name;

  override message: string = 'Not enough text content for exam.';

  static isInstance(error: unknown): error is NotEnoughContentForExamError {
    return (error as NotEnoughContentForExamError).isNotEnoughContentForExamError;
  }
}

export class NotEnoughQuestionsForExamError extends Error {
  readonly isNotEnoughQuestionsForExamError: boolean = true;

  override name: string = NotEnoughQuestionsForExamError.name;

  override message: string = 'Error while generating questions. Not enough questions for exam.';

  static isInstance(error: unknown): error is NotEnoughQuestionsForExamError {
    return (error as NotEnoughQuestionsForExamError).isNotEnoughQuestionsForExamError;
  }
}
