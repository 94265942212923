import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { ButtonComponent } from '@stsm/ui-components/button';

@Component({
  selector: 'app-flashcard-result-presentation',
  templateUrl: './flashcard-result-presentation.component.html',
  styleUrls: ['./flashcard-result-presentation.component.scss'],
  imports: [ButtonComponent, NgIf, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlashcardResultPresentationComponent {
  @Input() headingTranslationKey: string = '';
  @Input() buttonTranslationKey: string = '';
  @Input() closeTranslationKey: string | undefined;
  @Input() confirmButtonHasPremiumLock: boolean = false;

  @Output() readonly buttonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly closeRequest: EventEmitter<void> = new EventEmitter<void>();
}
