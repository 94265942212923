<div class="flashcard-confirmation-container">
  <i data-cy="flashcard-result-presentation-close-button" class="app-icon-close" (click)="closeRequest.emit()"></i>

  <div *ngIf="headingTranslationKey" class="congratulations-text">
    {{ headingTranslationKey | translate }}
  </div>

  <div class="content-wrapper">
    <ng-content />
  </div>

  <section>
    <div>
      <button
        *ngIf="closeTranslationKey"
        uiButton
        secondary
        data-cy="flashcard-result-presentation-close-quiz-button"
        (click)="closeRequest.emit()"
      >
        {{ closeTranslationKey | translate }}
      </button>

      <button uiButton data-cy="flashcard-result-presentation-restart-quiz-button" class="confirm-button" (click)="buttonClick.emit()">
        {{ buttonTranslationKey | translate }}
      </button>
    </div>
  </section>
</div>
