import { isNil } from 'lodash-es';

import { Id } from '@stsm/shared/types/id';

import { Flashcard, FlashcardFactory } from './flashcard';

export interface SmartExam {
  id: Id;
  amount: number;
  questionEntries: ExamQuestionEntry[];
}

export interface ExamQuestionEntry {
  question: string;
  answer: string;
  review: string;
  score: number | undefined;
  scoreResponseMessage?: string;
}

export function transformSmartExamToFlashcards(
  smartExam: SmartExam,
  shouldFormatChatReview: boolean = false,
  scoreThreshold: number = 6,
): Flashcard[] {
  return smartExam.questionEntries.map((questionEntry: ExamQuestionEntry) =>
    FlashcardFactory.create({
      isExamAiQuestion: true,
      isTypeAnswer: true,
      questionFlashcardEntry: {
        text: formatNewLines(questionEntry.question),
        isCorrect: true,
      },
      answerFlashcardEntries: [
        {
          text: shouldFormatChatReview
            ? formatChatReview(formatNewLines(questionEntry.review), questionEntry.answer)
            : questionEntry.answer,
          isCorrect: !isNil(questionEntry.score) && questionEntry.score > scoreThreshold,
        },
      ],
    }),
  );
}

function formatNewLines(text: string): string {
  return text
    .split('\n')
    .map((line: string): string => `<p>${line || '&nbsp;'}</p>`)
    .join('');
}

function formatChatReview(text: string, userAnswer: string | undefined): string {
  let reviewText = text;

  if (userAnswer !== undefined) {
    reviewText = `<p class="repeated-user-answer">${userAnswer}</p><hr>${reviewText}`;
  }

  return reviewText;
}
