import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { clamp, isNil } from 'lodash-es';
import * as ProgressBar from 'progressbar.js';
import Circle from 'progressbar.js/circle';

import { AppColor } from '@stsm/shared/util/color-util';

/**
 * min progress normalized to range [0, 1]
 */
const MIN_PROGRESS: number = 0.001;

@Component({
  selector: 'ui-circle-progressbar',
  templateUrl: './circle-progressbar.component.html',
  styleUrls: ['./circle-progressbar.component.scss'],
  imports: [NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleProgressbarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('progressbar', { read: ElementRef }) progressbar?: ElementRef<HTMLDivElement>;

  /**
   * progress in percent
   */
  @Input({ required: true }) set progress(progress: number) {
    this._progress = clamp(Math.round(progress), 0, 100);
  }

  get progress(): number {
    return this._progress;
  }

  @Input() initialProgress: number = 0.1;

  @Input() color: AppColor = AppColor.ACCENT;

  @Input({ transform: booleanAttribute }) hideProgressPercentage: boolean = false;

  private _progressbar: Circle | undefined;
  private _progress: number = 0;

  ngAfterViewInit(): void {
    if (isNil(this.progressbar)) {
      return;
    }

    this._progressbar = new ProgressBar.Circle(this.progressbar.nativeElement, {
      strokeWidth: 5,
      color: `var(${this.color})`,
      trailColor: `var(${AppColor.BORDER})`,
      trailWidth: 3,
    });

    if (this._progressbar.path) {
      this._progressbar.path.style.strokeLinecap = 'round';
    }

    this._progressbar.set(clamp(Math.round(this.initialProgress) / 100, MIN_PROGRESS, 1));

    this._animate();
  }

  ngOnDestroy(): void {
    this._progressbar?.destroy();
  }

  private _animate(): void {
    const normalizedProgress = clamp(this.progress / 100, MIN_PROGRESS, 1);

    setTimeout(() => {
      if (!isNil(this._progressbar)) {
        this._progressbar.animate(normalizedProgress, {
          duration: 1400,
          easing: 'easeInOut',
        });
      }
    }, 300);
  }
}
