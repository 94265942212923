import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { catchError, concatMap, firstValueFrom, from, map, Observable, of, retry, RetryConfig, switchMap, timer } from 'rxjs';

import { Flashcard } from '@stsm/flashcards/types/flashcard';
import { FlashcardEntry } from '@stsm/flashcards/types/flashcard-entry';
import { ExamQuestionEntry, SmartExam } from '@stsm/flashcards/types/smart-exam';
import { NotEnoughContentForExamError } from '@stsm/flashcards/types/smart-exam-errors';
import { ChatGptPromptService, LanguageMapValue } from '@stsm/global/composite/services/chat-gpt-prompt.service';
import { GenericChatGptService } from '@stsm/global/composite/services/generic-chat-gpt.service';
import { AiFeature } from '@stsm/global/models/ai/ai-features';
import { PromptTemplateId } from '@stsm/global/models/ai/ai-prompt-template';
import {
  ChatCompletionMessage,
  ChatGptInput,
  isChatGptMessage,
  isChatGptMessageTemplate,
  OVER_API_LIMIT_ERROR_CODE,
} from '@stsm/global/models/ai/chat-gpt.interface';
import { ExamAiTemplateLanguage, isExamAiTemplateLanguage } from '@stsm/global/models/ai/exam-ai-prompts';
import { Language } from '@stsm/i18n/models/language';
import { DEFAULT_LANGUAGE, SupportedLanguage } from '@stsm/i18n/models/supported-language';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { Id } from '@stsm/shared/types/id';
import { VOID } from '@stsm/shared/util/rxjs.util';
import { Studyset } from '@stsm/studysets/models/studyset';

import { replaceHtmlTagsWithSeparator, replaceLatexFormulaWithSeparator } from '../typed-answer/util/text-util';

import { getAmountOfFlashcardsToSendToAi } from './smart-exam-count-util';

interface PromptBaseValues {
  languageParam: { language: LanguageMapValue };
  customTemplateLanguage?: ExamAiTemplateLanguage;
  options: { shouldTransformToContent: boolean };
}

interface ScoreResponse {
  score: number | undefined;
  scoreResponseMessage: string | undefined;
}

export type FlashcardForExamContent = Flashcard & { combinedTextContent: string };

/**
 * warning: This service is only for initial development and should be replaced by backend calls later on
 */
@Injectable({
  providedIn: 'root',
})
export class LocalSmartExamService {
  private static readonly _localStorageKeyLocalPrompts: string = 'smartExam-shouldTransformPromptsLocally';
  private static readonly _retryConfig: RetryConfig = {
    count: 2,
    delay: (error: unknown) => {
      if (error instanceof HttpErrorResponse && error.status === OVER_API_LIMIT_ERROR_CODE) {
        return VOID;
      }

      return timer(2000);
    },
    resetOnSuccess: true,
  };

  /**
   * @description
   * flag for development to send the complete prompts to the backend instead of the template names (will be ignored in production), in dev it can be set in the local storage
   */
  private readonly _shouldTransformPromptsLocally: boolean =
    this._browserStorageService.getItemLocalStorage(LocalSmartExamService._localStorageKeyLocalPrompts) ?? false;

  private readonly _aiFeature: AiFeature = 'exam';
  private _questionCreationConversationHistoryMap: Record<Id, ChatGptInput[]> = {};
  private _currentExamId: Id = 0;
  private _language: SupportedLanguage = DEFAULT_LANGUAGE;

  constructor(
    private readonly _genericChatGptService: GenericChatGptService,
    private readonly _loggerService: LoggerService,
    private readonly _chatGptPromptService: ChatGptPromptService,
    private readonly _browserStorageService: BrowserStorageService,
    private readonly _translationService: TranslationService,
  ) {
    this._translationService.language$.subscribe((language: Language) => {
      this._language = language.value;
    });
  }

  /**
   * @description
   * Returns a number between 1 and 10 based on the response message of the AI.
   * If the response message does not contain a score or the parsed score is greater than 10, undefined is returned.
   * If the message contains two numbers, the smaller one is returned.
   */
  static parseScoreFromText(text: string = '', minScore: number = 1, maxScore: number = 10): number | undefined {
    const numberMatcher: RegExp = /(-?\d+(?:.\d+)?)/g;
    const foundNumbers: number[] = (text.match(numberMatcher) ?? [])
      .map((score: string) => parseInt(score, 10))
      .filter((score: number) => !isNaN(score));
    const smallestNumber: number | undefined = Math.min(...foundNumbers);
    const score: number = Math.max(smallestNumber, minScore);

    return isNaN(score) ? undefined : score > maxScore ? undefined : score;
  }

  /**
   * @description
   * generate exam questions using ChatGPT
   * @param studyset studyset to generate questions for
   * @param examQuantity number of questions to generate
   * @param providedFlashcards array of flashcards to use for the exam
   */
  getExamQuestions$(studyset: Studyset, examQuantity: number, providedFlashcards: FlashcardForExamContent[]): Observable<SmartExam> {
    this._loggerService.debug('getExamQuestions$() start', { studyset, examQuantity, providedFlashcards });

    const id: Id = this._currentExamId++;

    let conversationHistory: ChatGptInput[] = [];
    const flashcardContentList: string[] = providedFlashcards.map((flashcard: FlashcardForExamContent) => flashcard.combinedTextContent);

    if (!flashcardContentList.length) {
      throw new NotEnoughContentForExamError();
    }

    const amountOfFlashcardsToSendToAi = getAmountOfFlashcardsToSendToAi(examQuantity);
    const flashcardContent = flashcardContentList.slice(0, amountOfFlashcardsToSendToAi).join('\n');

    this._loggerService.debug(flashcardContent);

    let actualExamQuantity = Math.min(examQuantity, flashcardContentList.length);

    return from([...Array(actualExamQuantity).keys()]).pipe(
      concatMap(async (index: number): Promise<SmartExam> => {
        this._loggerService.debug(`Creating smart exam question ${index + 1} ...`);

        try {
          conversationHistory = await this._getExamQuestion(studyset, conversationHistory, flashcardContent);
        } catch (error) {
          actualExamQuantity--;

          this._loggerService.error('getExamQuestions$() error, will skip this question', error);
        }

        const questionEntries: ExamQuestionEntry[] = this._getQuestionEntriesFromConversationHistory(conversationHistory);
        this._questionCreationConversationHistoryMap[id] = conversationHistory;

        this._logConversationLengths(this._questionCreationConversationHistoryMap[id]);

        this._loggerService.debug('getExamQuestions$() end', { id, questionEntries });

        return {
          id,
          amount: actualExamQuantity,
          questionEntries,
        };
      }),
    );
  }

  /**
   * @description
   * review user answer using ChatGPT
   * @param smartExam smart exam to review
   * @param userAnswer user answer to review
   * @param questionEntry question the user answer belongs to
   */
  reviewUserAnswer(smartExam: SmartExam, userAnswer: string, questionEntry: ExamQuestionEntry): Observable<ExamQuestionEntry> {
    this._loggerService.debug('reviewUserAnswer()', { smartExam, userAnswer, questionEntry });
    const conversationHistory = this._getFakeConversationHistoryForReview(smartExam, questionEntry);
    const reviewMessage = this._getReviewMessage(userAnswer);
    const messages = [...conversationHistory, reviewMessage];

    return this._genericChatGptService.getChatCompletions({ messages, feature: this._aiFeature }).pipe(
      map((responseMessages: ChatCompletionMessage[]) => {
        const responseMessage: ChatCompletionMessage | undefined = responseMessages[0];
        this._loggerService.debug(`Received review: ${responseMessage?.content}`);

        if (responseMessage === undefined) {
          throw new Error('Failed to generate exam review');
        }

        return responseMessage;
      }),
      switchMap((responseMessage: ChatCompletionMessage) => {
        const responseWithoutScore: ScoreResponse & { responseMessage: ChatCompletionMessage } = {
          score: undefined,
          scoreResponseMessage: undefined,
          responseMessage,
        };

        if (userAnswer) {
          return this._retrieveScoreForReview([...messages, responseMessage]).pipe(
            map((scoreResponse: ScoreResponse) => {
              return { ...scoreResponse, responseMessage };
            }),
            catchError((error: unknown) => {
              this._loggerService.error('reviewUserAnswer() failed to fetch review score', error);

              return of(responseWithoutScore);
            }),
          );
        }

        return of(responseWithoutScore);
      }),
      map(({ score, scoreResponseMessage, responseMessage }: ScoreResponse & { responseMessage: ChatCompletionMessage }) => {
        const review = responseMessage.content ?? '';

        const updatedQuestionEntry: ExamQuestionEntry = {
          ...questionEntry,
          answer: userAnswer || '-',
          review,
          score,
          scoreResponseMessage,
        };

        this._logConversationLengths([...messages, responseMessage]);
        this._loggerService.debug('reviewUserAnswer() end', updatedQuestionEntry);

        return updatedQuestionEntry;
      }),
    );
  }

  getFilteredFlashcardsSuitableForAi(flashcards: Flashcard[]): FlashcardForExamContent[] {
    return this._getFlashcardContentListWithMapping(flashcards);
  }

  private _retrieveScoreForReview(conversationHistory: ChatGptInput[]): Observable<ScoreResponse> {
    const { options, customTemplateLanguage } = this._getPromptBaseValues();
    const template: PromptTemplateId = customTemplateLanguage ? `exam_review_score_${customTemplateLanguage}` : 'exam_review_score';

    const gradePrompt = this._chatGptPromptService.getChatGptInput({ role: 'user', template, values: {} }, options);

    return this._genericChatGptService
      .getChatCompletions({
        messages: [...conversationHistory, gradePrompt],
        feature: this._aiFeature,
      })
      .pipe(
        map((response: ChatCompletionMessage[]) => {
          const scoreResponseMessage: ChatCompletionMessage | undefined = response[0];
          this._loggerService.debug(`Received score: ${scoreResponseMessage?.content}`);

          return {
            score: !isNil(scoreResponseMessage?.content)
              ? LocalSmartExamService.parseScoreFromText(scoreResponseMessage?.content)
              : undefined,
            scoreResponseMessage: scoreResponseMessage?.content ?? undefined,
          };
        }),
      );
  }

  private _formatChatQuestion(text: string): string {
    return text.replace('Question: ', '').replace('Frage: ', '').replace('Pregunta: ', '');
  }

  private async _getExamQuestion(
    studyset: Studyset,
    conversationHistory: ChatGptInput[],
    flashcardContent: string,
  ): Promise<ChatGptInput[]> {
    let messages: ChatGptInput[];

    if (conversationHistory.length) {
      messages = [...conversationHistory, this._getNextQuestionMessage()];
    } else {
      messages = this._getExamPromptBaseMessages(studyset, flashcardContent);
    }

    const responseMessage: ChatCompletionMessage | undefined = (
      await firstValueFrom(
        this._genericChatGptService
          .getChatCompletions({ messages, feature: this._aiFeature })
          .pipe(retry(LocalSmartExamService._retryConfig)),
      )
    )[0];

    this._loggerService.debug(`Received question: ${responseMessage?.content}`);

    if (responseMessage === undefined) {
      throw new Error('Failed to generate exam question');
    }

    return [...messages, responseMessage];
  }

  private _getExamPromptBaseMessages(studyset: Studyset, flashcardContent: string): ChatGptInput[] {
    const { languageParam, options, customTemplateLanguage }: PromptBaseValues = this._getPromptBaseValues();
    const introTemplate: PromptTemplateId = customTemplateLanguage ? `exam_system_intro_${customTemplateLanguage}` : 'exam_system_intro';
    const questionFromFlashcardsTemplate: PromptTemplateId = customTemplateLanguage
      ? `exam_question_from_flashcards_${customTemplateLanguage}`
      : 'exam_question_from_flashcards';

    const baseMessages: ChatGptInput[] = [
      this._chatGptPromptService.getChatGptInput(
        {
          role: 'system',
          template: introTemplate,
          values: languageParam,
        },
        options,
      ),
    ];

    baseMessages.push(
      this._chatGptPromptService.getChatGptInput(
        {
          role: 'user',
          template: questionFromFlashcardsTemplate,
          values: {
            studyset_name: studyset.name,
            flashcard_content: flashcardContent,
            ...languageParam,
          },
        },
        options,
      ),
    );

    return baseMessages;
  }

  private _getNextQuestionMessage(): ChatGptInput {
    const { languageParam, options, customTemplateLanguage }: PromptBaseValues = this._getPromptBaseValues();
    const template: PromptTemplateId = customTemplateLanguage ? `exam_next_question_${customTemplateLanguage}` : 'exam_next_question';

    return this._chatGptPromptService.getChatGptInput(
      {
        role: 'user',
        template,
        values: languageParam,
      },
      options,
    );
  }

  private _getReviewMessage(userAnswer: string): ChatGptInput {
    const { languageParam, options, customTemplateLanguage }: PromptBaseValues = this._getPromptBaseValues();
    const userAnswerTemplate: PromptTemplateId = customTemplateLanguage ? `exam_review_${customTemplateLanguage}` : 'exam_review';
    const emptyAnswerTemplate: PromptTemplateId = customTemplateLanguage
      ? `exam_review_empty_answer_${customTemplateLanguage}`
      : 'exam_review_empty_answer';

    return userAnswer
      ? this._chatGptPromptService.getChatGptInput(
          {
            role: 'user',
            template: userAnswerTemplate,
            values: {
              user_answer: userAnswer,
              ...languageParam,
            },
          },
          options,
        )
      : this._chatGptPromptService.getChatGptInput(
          {
            role: 'user',
            template: emptyAnswerTemplate,
            values: {
              ...languageParam,
            },
          },
          options,
        );
  }

  /**
   * @description Creates a fake conversation history based on the initial question creation prompt and the current question.
   * Simulates that the API gave the current question as first question by adding an entry with role assistant.
   */
  private _getFakeConversationHistoryForReview(smartExam: SmartExam, questionEntry: ExamQuestionEntry): ChatGptInput[] {
    let conversationHistory: ChatGptInput[] = this._questionCreationConversationHistoryMap[smartExam.id] ?? [];
    const firstAssistantMessageIndex: number = conversationHistory.findIndex((message: ChatGptInput) => message.role === 'assistant');
    conversationHistory = conversationHistory.slice(0, firstAssistantMessageIndex);

    conversationHistory.push({
      role: 'assistant',
      content: questionEntry.question,
    });

    return conversationHistory;
  }

  private _getQuestionEntriesFromConversationHistory(conversationHistory: ChatGptInput[]): ExamQuestionEntry[] {
    return conversationHistory
      .filter((message: ChatGptInput) => message.role === 'assistant')
      .map((message: ChatGptInput) => {
        const questionText = isChatGptMessage(message) && !isNil(message.content) ? this._formatChatQuestion(message.content) : '';

        return {
          question: questionText,
          answer: '',
          review: '',
          score: undefined,
        };
      });
  }

  private _getFlashcardContentListWithMapping(flashcards: Flashcard[] = []): FlashcardForExamContent[] {
    return flashcards
      .map((flashcard: Flashcard) => ({
        ...flashcard,
        combinedTextContent: this._getCombinedFlashcardTextContent(flashcard),
      }))
      .filter(({ combinedTextContent }: FlashcardForExamContent) => this._shouldIncludeForAiRequest(combinedTextContent))
      .map((flashcard: FlashcardForExamContent) => ({
        ...flashcard,
        combinedTextContent: replaceHtmlTagsWithSeparator(replaceLatexFormulaWithSeparator(flashcard.combinedTextContent)),
      }));
  }

  private _getCombinedFlashcardTextContent(flashcard: Flashcard): string {
    return `Q: ${flashcard.questionFlashcardEntry.text}; A: ${flashcard.answerFlashcardEntries
      .filter((entry: FlashcardEntry) => entry.isCorrect)
      .map((entry: FlashcardEntry) => entry.text)
      .join(', ')}`;
  }

  private _shouldIncludeForAiRequest(text: string): boolean {
    return text.length > 0 && !text.includes('<img') && !text.includes('Wirisformula') && !text.includes('data-mathml');
  }

  private _logConversationLengths(messages: ChatGptInput[]): void {
    const hasTemplates = messages.some((message: ChatGptInput) => isChatGptMessageTemplate(message));

    if (hasTemplates) {
      this._loggerService.debug(`Messages contain templates. Calculated lengths will only include the content of the messages.`);
    }

    const contentString: string = messages.map((message: ChatGptInput) => (isChatGptMessage(message) ? message.content : '')).join(' ');
    this._loggerService.debug(`Conversation character length: ${contentString.length}`);
    this._loggerService.debug(`Conversation word length: ${contentString.split(' ').length}`);
  }

  private _getPromptBaseValues(): PromptBaseValues {
    const languageParam = this._chatGptPromptService.getLanguageParam();
    const options = { shouldTransformToContent: this._shouldTransformPromptsLocally };
    const customTemplateLanguage = isExamAiTemplateLanguage(this._language) ? this._language : undefined;

    return { languageParam, options, customTemplateLanguage };
  }
}
