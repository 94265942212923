import { SupportedLanguage } from '@stsm/i18n/models/supported-language';

const EXAM_PROMPT_TEMPLATES = [
  'exam_system_intro',
  'exam_question_from_flashcards',
  'exam_next_question',
  'exam_review',
  'exam_review_empty_answer',
  'exam_review_score',
] as const;

const EXAM_AI_TEMPLATE_LANGUAGES: readonly Extract<SupportedLanguage, 'de' | 'fr' | 'es'>[] = ['de', 'fr', 'es'] as const;

export type ExamAiTemplateLanguage = (typeof EXAM_AI_TEMPLATE_LANGUAGES)[number];

export function isExamAiTemplateLanguage(language: string): language is ExamAiTemplateLanguage {
  return EXAM_AI_TEMPLATE_LANGUAGES.includes(language as ExamAiTemplateLanguage);
}

type ExamPromptTemplateBaseId = (typeof EXAM_PROMPT_TEMPLATES)[number];

type ExamPromptTemplateIdByLanguage<Language extends SupportedLanguage> = `${ExamPromptTemplateBaseId}_${Language}`;

export type ExamPromptTemplateId = ExamPromptTemplateBaseId | ExamPromptTemplateIdByLanguage<ExamAiTemplateLanguage>;
