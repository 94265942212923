import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComponentStore } from '@ngrx/component-store';
import { isNil, shuffle } from 'lodash-es';
import { combineLatest, finalize, map, Observable, of, Subscription, switchMap, take } from 'rxjs';

import { Flashcard } from '@stsm/flashcards/types/flashcard';
import { ExamQuestionEntry, SmartExam, transformSmartExamToFlashcards } from '@stsm/flashcards/types/smart-exam';
import { NotEnoughQuestionsForExamError } from '@stsm/flashcards/types/smart-exam-errors';
import { ComponentStoreDevToolsService, LinkedComponentStore } from '@stsm/global/composite/services/component-store-dev-tools.service';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { Id } from '@stsm/shared/types/id';
import { Studyset } from '@stsm/studysets/models/studyset';

import { FlashcardForExamContent, LocalSmartExamService } from './local-smart-exam.service';
import { getAmountOfExamQuestionsForQuiz } from './smart-exam-count-util';

interface ExamIntegrationState {
  readonly flashcardIdsToBeReplacedByExamQuestions: Id[];
  readonly smartExam?: SmartExam;
  readonly flashcards: Flashcard[];
  readonly isLoadingQuestions: boolean;
  readonly readyReplacements: number;
  readonly currentIndex: number;
}

const initialState: ExamIntegrationState = {
  flashcardIdsToBeReplacedByExamQuestions: [],
  smartExam: undefined,
  flashcards: [],
  isLoadingQuestions: false,
  readyReplacements: 0,
  currentIndex: 0,
};

/**
 * Info: this will move to the backend in a future step
 */
@UntilDestroy()
@Injectable()
export class ExamIntegrationStore extends ComponentStore<ExamIntegrationState> {
  readonly smartExam$: Observable<SmartExam | undefined> = this.select(({ smartExam }: ExamIntegrationState) => smartExam);
  readonly currentIndex$: Observable<number> = this.select(({ currentIndex }: ExamIntegrationState) => currentIndex);
  readonly currentExamEntry$: Observable<ExamQuestionEntry | undefined> = this.select(
    ({ smartExam, currentIndex }: ExamIntegrationState) => smartExam?.questionEntries[currentIndex],
  );

  readonly setLoadingQuestions: (isLoadingQuestions: boolean) => Subscription = this.updater(
    (state: ExamIntegrationState, isLoadingQuestions: boolean): ExamIntegrationState => ({
      ...state,
      isLoadingQuestions,
    }),
  );

  readonly setFlashcardIdsToBeReplacedByExamQuestions: (flashcardIdsToBeReplacedByExamQuestions: Id[]) => Subscription = this.updater(
    (state: ExamIntegrationState, flashcardIdsToBeReplacedByExamQuestions: Id[]): ExamIntegrationState => ({
      ...state,
      flashcardIdsToBeReplacedByExamQuestions,
    }),
  );

  readonly updateCurrentExamEntry: (examEntry: ExamQuestionEntry | undefined) => Subscription = this.updater(
    (state: ExamIntegrationState, examEntry: ExamQuestionEntry | undefined): ExamIntegrationState => {
      if (!isNil(state.smartExam) && !isNil(examEntry)) {
        return {
          ...state,
          smartExam: {
            ...state.smartExam,
            questionEntries: state.smartExam.questionEntries.map((entry: ExamQuestionEntry, index: number) =>
              index === state.currentIndex ? examEntry : entry,
            ),
          },
        };
      }

      return state;
    },
  );

  constructor(
    private readonly _localSmartExamService: LocalSmartExamService,
    private readonly _componentStoreDevToolsService: ComponentStoreDevToolsService,
    private readonly _loggerService: LoggerService,
  ) {
    super(initialState);
    this._componentStoreDevToolsService.linkComponentStoreToGlobalState(this.state$, LinkedComponentStore.EXAM_INTEGRATION);
  }

  questionIsReady$(flashcardId: Id): Observable<boolean> {
    return this.select(
      ({ flashcardIdsToBeReplacedByExamQuestions, readyReplacements, isLoadingQuestions }: ExamIntegrationState): boolean => {
        const index: number = flashcardIdsToBeReplacedByExamQuestions.indexOf(flashcardId);

        if (index === -1) {
          return true;
        }

        const isQuestionReady: boolean = readyReplacements > index;

        if (!isQuestionReady && !isLoadingQuestions) {
          throw new NotEnoughQuestionsForExamError();
        }

        return isQuestionReady;
      },
    );
  }

  getExamQuestionsIfApplicable$(flashcard: Flashcard): Observable<Flashcard> {
    return this.state$.pipe(
      map(({ flashcardIdsToBeReplacedByExamQuestions, flashcards, currentIndex }: ExamIntegrationState): Flashcard => {
        return flashcardIdsToBeReplacedByExamQuestions.includes(flashcard.id) && flashcards[currentIndex]
          ? flashcards[currentIndex]
          : flashcard;
      }),
      take(1),
    );
  }

  setupExamQuestionCandidatesAndReturnAdaptedFlashcardList(
    studyset: Studyset,
    flashcards: Flashcard[],
    targetTotalCount: number,
    isOnlyExamType: boolean,
  ): Flashcard[] {
    const countForExamQuestions: number = getAmountOfExamQuestionsForQuiz(targetTotalCount, { isOnlyExamType });
    const countForExamQuestionContent: number = isOnlyExamType ? flashcards.length : countForExamQuestions;

    const flashcardsForExamContent: FlashcardForExamContent[] = this._getRandomFlashcardsSuitableForExamQuestions(
      flashcards,
      isOnlyExamType,
    ).slice(0, countForExamQuestionContent);

    // if there are not enough flashcards for the exam, we need to reduce the amount of exam questions
    const actualExamQuestionCount = Math.min(countForExamQuestions, flashcardsForExamContent.length);
    const countForRegularQuestions = isOnlyExamType ? 0 : Math.round(targetTotalCount - actualExamQuestionCount);

    this._loggerService.debug(
      'desiredCountForExamQuestions: ',
      countForExamQuestions,
      ' actualExamQuestionCount: ',
      actualExamQuestionCount,
      ' targetTotalCount: ',
      targetTotalCount,
    );

    this.setLoadingQuestions(true);

    this._localSmartExamService
      .getExamQuestions$(studyset, actualExamQuestionCount, flashcardsForExamContent)
      .pipe(
        untilDestroyed(this),
        finalize(() => this.setLoadingQuestions(false)),
      )
      .subscribe({
        next: (smartExam: SmartExam) => {
          // save results asynchronously - we don't need to wait for this
          const transformedFlashcards: Flashcard[] = transformSmartExamToFlashcards(smartExam);
          this.patchState({
            smartExam,
            flashcards: transformedFlashcards,
            readyReplacements: transformedFlashcards.length,
          });
        },
        error: (error: unknown) => {
          this._loggerService.error('Error while loading exam questions', error);
          this._loggerService.debug(this.get().flashcards?.length, 'questions already loaded, out of ', actualExamQuestionCount);
        },
      });

    const flashcardIdsInExamContent = flashcardsForExamContent.map((flashcard: Flashcard): number => flashcard.id);

    // remove buffer flashcards from exam questions
    const flashcardIdsForExamQuestions = flashcardIdsInExamContent.slice(0, actualExamQuestionCount);

    // sort replacement ids by original order in flashcards array
    flashcardIdsForExamQuestions.sort((a: number, b: number): number => {
      return (
        flashcards.findIndex((flashcard: Flashcard): boolean => flashcard.id === a) -
        flashcards.findIndex((flashcard: Flashcard): boolean => flashcard.id === b)
      );
    });

    this.setFlashcardIdsToBeReplacedByExamQuestions(flashcardIdsForExamQuestions);

    // remove exam questions from regular questions
    const flashcardIdsForRegularQuestions = isOnlyExamType
      ? []
      : flashcards
          .filter((flashcard: Flashcard): boolean => !flashcardIdsInExamContent.includes(flashcard.id))
          .map((flashcard: Flashcard): number => flashcard.id)
          .slice(0, countForRegularQuestions);

    return flashcards
      .filter((flashcard: Flashcard): boolean => {
        return flashcardIdsForRegularQuestions.includes(flashcard.id) || flashcardIdsForExamQuestions.includes(flashcard.id);
      })
      .slice(0, targetTotalCount); // for double safety
  }

  answerReview$(userAnswer: string): Observable<{ flashcard: Flashcard; examEntry: ExamQuestionEntry } | undefined> {
    return combineLatest([this.smartExam$, this.currentIndex$]).pipe(
      take(1),
      switchMap(([smartExam, currentIndex]: [SmartExam | undefined, number]) => {
        if (isNil(smartExam)) {
          return of(undefined);
        }

        const questionEntry = smartExam.questionEntries[currentIndex];

        if (isNil(questionEntry)) {
          return of(undefined);
        }

        return this._localSmartExamService.reviewUserAnswer(smartExam, userAnswer, questionEntry).pipe(
          map((questionEntry: ExamQuestionEntry) => {
            const updatedSmartExam: SmartExam = {
              ...smartExam,
              questionEntries: smartExam.questionEntries.map((entry: ExamQuestionEntry, index: number): ExamQuestionEntry => {
                return index === currentIndex ? questionEntry : entry;
              }),
            };
            const transformedFlashcards: Flashcard[] = transformSmartExamToFlashcards(updatedSmartExam);
            this.patchState((state: ExamIntegrationState): ExamIntegrationState => {
              return {
                ...state,
                flashcards: transformedFlashcards,
                smartExam: updatedSmartExam,
              };
            });

            return {
              flashcard: transformedFlashcards[currentIndex] as Flashcard,
              examEntry: questionEntry,
            };
          }),
        );
      }),
    );
  }

  reset(): void {
    this.setState(initialState);
  }

  incrementIndex(): void {
    this.patchState(
      (state: ExamIntegrationState): ExamIntegrationState => ({
        ...state,
        currentIndex: state.currentIndex + 1,
      }),
    );
  }

  private _getRandomFlashcardsSuitableForExamQuestions(
    flashcards: Flashcard[],
    isOnlyExamType: boolean = false,
  ): FlashcardForExamContent[] {
    // always use first flashcard with regular mode
    const filteredBaseFlashcards: Flashcard[] = isOnlyExamType ? flashcards : flashcards.slice(1);

    // filter out flashcards with images
    let filteredFlashcards = this._localSmartExamService.getFilteredFlashcardsSuitableForAi(filteredBaseFlashcards);

    // shuffle
    filteredFlashcards = shuffle(filteredFlashcards);

    this._loggerService.debug('filtered and shuffled flashcards for exam content', filteredFlashcards);

    return filteredFlashcards;
  }
}
