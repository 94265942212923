import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComponentStore } from '@ngrx/component-store';
import { isNil } from 'lodash-es';
import {
  catchError,
  combineLatest,
  combineLatestWith,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  Observable,
  of,
  pipe,
  Subscription,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';

import { AdsStatusService } from '@stsm/advertisement/services/ads-status.service';
import { AD_FLASHCARD_ID, Flashcard } from '@stsm/flashcards/types/flashcard';
import { FlashcardFilterSettings } from '@stsm/flashcards/types/flashcard-filter-settings';
import { FlashcardQuestionType } from '@stsm/flashcards/types/flashcard-question-type';
import { ExamQuestionEntry } from '@stsm/flashcards/types/smart-exam';
import { NotEnoughContentForExamError, NotEnoughQuestionsForExamError } from '@stsm/flashcards/types/smart-exam-errors';
import { ComponentStoreDevToolsService, LinkedComponentStore } from '@stsm/global/composite/services/component-store-dev-tools.service';
import { NavigationBaseService } from '@stsm/global/composite/services/navigation-base.service';
import { NAVIGATION_SERVICE } from '@stsm/global/composite/tokens/navigation-service.token';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { ReviewType } from '@stsm/quiz/lib/components/exam-review-footer';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { SentryService } from '@stsm/shared/services/sentry.service';
import { Id } from '@stsm/shared/types/id';
import { distinctNumberFromParamMap, filterUndefined, shareReplayRefCount } from '@stsm/shared/util/rxjs.util';
import { Studyset } from '@stsm/studysets/models/studyset';
import { StudysetsStoreFacade } from '@stsm/studysets/store/studysets-store-facade.service';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';

import { FlashcardQuizService, FlashcardsLoadingInfo } from '../services/flashcard-quiz.service';
import { FlashcardsAdService, nextFlashcardInSequenceIsAd } from '../services/flashcards-ad.service';
import { QuizTrackingService } from '../services/quiz-tracking.service';

import { ExamIntegrationStore } from './exam-integration.store';

interface QuizPageState {
  studyset?: Studyset;
  flashcards: Flashcard[];
  currentFlashcardIndex: number;
  showResult: boolean;
  showAd: boolean;
  isOnlyExamType: boolean;
  isLoadingQuestions: boolean;
  isLoadingCurrentQuestion: boolean;
  isLoadingReview: boolean;
  showSkipButton: boolean;
  isReviewError: boolean;
  reviewSkippedCount: number;
  reviewErrorCount: number;
  isCorrectAnswer: boolean;
  correctAnswersCount: number;
  isSessionFinished: boolean;
  customTotalCount: number;
}

export interface QuizPageViewModel extends QuizPageState {
  currentFlashcard?: Flashcard;
  currentExamEntry: ExamQuestionEntry | undefined;
  totalCount: number;
  progress: number;
  showLoadingScreen: boolean;
  showProgress: boolean;
  showAnswerInput: boolean;
  showCorrectAnswer: boolean;
  reviewType: ReviewType;
}

const initialState: QuizPageState = {
  studyset: undefined,
  flashcards: [],
  currentFlashcardIndex: 0,
  showResult: false,
  showAd: false,
  isOnlyExamType: false,
  isLoadingQuestions: false,
  isLoadingCurrentQuestion: false,
  isLoadingReview: false,
  showSkipButton: false,
  isReviewError: false,
  reviewSkippedCount: 0,
  reviewErrorCount: 0,
  isCorrectAnswer: false,
  correctAnswersCount: 0,
  isSessionFinished: false,
  customTotalCount: 0,
};

@UntilDestroy()
@Injectable()
export class QuizPageStore extends ComponentStore<QuizPageState> {
  readonly studyset$: Observable<Studyset> = this.select((state: QuizPageState) => state.studyset).pipe(filterUndefined());
  readonly currentFlashcardIndex$: Observable<number> = this.select((state: QuizPageState) => state.currentFlashcardIndex);
  readonly isSessionFinished$: Observable<boolean> = this.select((state: QuizPageState) => state.isSessionFinished);
  readonly isOnlyExamType$: Observable<boolean> = this.select((state: QuizPageState) => state.isOnlyExamType);
  readonly flashcards$: Observable<Flashcard[]> = this.select((state: QuizPageState) => state.flashcards);

  readonly currentFlashcard$: Observable<Flashcard> = this.currentFlashcardIndex$.pipe(
    combineLatestWith(this.flashcards$),
    map(([currentFlashcardIndex, flashcards]: [number, Flashcard[]]) => flashcards[currentFlashcardIndex]),
    filterUndefined(),
  );

  readonly currentFlashcardOrExamQuestion$: Observable<Flashcard> = this.currentFlashcard$.pipe(
    switchMap((currentFlashcard: Flashcard) =>
      this._waitForQuestionToLoad(currentFlashcard.id).pipe(
        switchMap(() => {
          return this._examIntegrationStore.getExamQuestionsIfApplicable$(currentFlashcard);
        }),
      ),
    ),
    shareReplayRefCount(1),
  );

  readonly currentAd$: Observable<Flashcard> = this.select((state: QuizPageState) => state.showAd).pipe(
    map((showAd: boolean) => (showAd ? this._flashcardsAdService.getAdFlashcard(AD_FLASHCARD_ID) : undefined)),
    filterUndefined(),
    shareReplayRefCount(1),
  );

  readonly currentExamFlashcard$: Observable<Flashcard | undefined> = this.state$.pipe(
    switchMap(({ flashcards, currentFlashcardIndex }: QuizPageState) => {
      const currentFlashcard = flashcards[currentFlashcardIndex];

      return isNil(currentFlashcard) ? of(undefined) : this._examIntegrationStore.getExamQuestionsIfApplicable$(currentFlashcard);
    }),
  );

  readonly viewModel$: Observable<QuizPageViewModel> = this.select(
    this.state$,
    this.currentExamFlashcard$,
    this._examIntegrationStore.currentExamEntry$,
    (state: QuizPageState, currentFlashcard: Flashcard | undefined, currentExamEntry: ExamQuestionEntry | undefined) => {
      const {
        flashcards,
        currentFlashcardIndex,
        showResult,
        isLoadingCurrentQuestion,
        isLoadingQuestions,
        isLoadingReview,
        isCorrectAnswer,
        isReviewError,
        showAd,
      } = state;

      const totalCount: number = flashcards.length;
      const progress: number = ((currentFlashcardIndex + 1) / totalCount) * 100;
      const showProgress: boolean = !!currentFlashcard;
      const showLoadingScreen: boolean = isLoadingQuestions || isLoadingCurrentQuestion;
      const showAnswerInput: boolean = !showResult && (currentFlashcard?.isTypeAnswer ?? false) && !showLoadingScreen && !showAd;
      const showCorrectAnswer: boolean = showResult && (currentFlashcard?.isTypeAnswer ?? false) && !isCorrectAnswer;
      const reviewType: ReviewType = isReviewError || isLoadingReview ? 'neutral' : isCorrectAnswer ? 'good' : 'bad';

      return {
        ...state,
        currentFlashcard,
        currentExamEntry,
        totalCount,
        progress,
        showProgress,
        showLoadingScreen,
        showAnswerInput,
        showCorrectAnswer,
        reviewType,
      };
    },
    { debounce: true },
  );

  readonly setIsLoadingReview: (isLoadingReview: boolean) => Subscription = this.updater(
    (state: QuizPageState, isLoadingReview: boolean): QuizPageState => ({
      ...state,
      isLoadingReview,
    }),
  );

  readonly showResult: () => Subscription = this.updater(
    (state: QuizPageState): QuizPageState => ({
      ...state,
      showResult: true,
    }),
  );

  readonly showSkipButton: () => Subscription = this.updater(
    (state: QuizPageState): QuizPageState => ({
      ...state,
      showSkipButton: true,
    }),
  );

  readonly goToNextFlashcard: () => Subscription = this.updater((state: QuizPageState): QuizPageState => {
    const { flashcards, currentFlashcardIndex } = state;

    const isLastFlashcard: boolean = flashcards.length <= currentFlashcardIndex + 1;

    const update: QuizPageState = {
      ...state,
      showSkipButton: false,
      isReviewError: false,
      isSessionFinished: false,
    };

    const newAdSequence = this._flashcardsAdService.isTimeForNextAd();

    if (isLastFlashcard && !nextFlashcardInSequenceIsAd(newAdSequence)) {
      return { ...update, isSessionFinished: true };
    }

    // Check for Ad
    if (nextFlashcardInSequenceIsAd(newAdSequence)) {
      return {
        ...update,
        showAd: true,
        showResult: false,
      };
    }

    return {
      ...update,
      currentFlashcardIndex: state.currentFlashcardIndex + 1,
      showAd: false,
      showResult: false,
    };
  });

  private _hasTechnicalError: boolean = false;

  constructor(
    private readonly _studysetsStoreFacade: StudysetsStoreFacade,
    @Inject(NAVIGATION_SERVICE) private readonly _navigationService: NavigationBaseService,
    private readonly _sentryService: SentryService,
    private readonly _loggerService: LoggerService,
    private readonly _route: ActivatedRoute,
    private readonly _flashcardQuizService: FlashcardQuizService,
    private readonly _flashcardsAdService: FlashcardsAdService,
    private readonly _examIntegrationStore: ExamIntegrationStore,
    private readonly _simpleDialogService: SimpleDialogService,
    private readonly _translationService: TranslationService,
    private readonly _componentStoreDevToolsService: ComponentStoreDevToolsService,
    private readonly _quizTrackingService: QuizTrackingService,
    private readonly _adsStatusService: AdsStatusService,
  ) {
    super(initialState);

    this._flashcardsAdService.init({ shouldShowTwoAds: false });

    this._componentStoreDevToolsService.linkComponentStoreToGlobalState(this.state$, LinkedComponentStore.QUIZ);

    const studysetId$ = this._route.paramMap.pipe(
      distinctNumberFromParamMap('studysetId', { onError: () => this._navigationService.navigateToLibrary() }),
    );

    this.effect(
      pipe(
        switchMap((studysetId: Id) => this._studysetsStoreFacade.studysetById(studysetId)),
        tap((studyset: Studyset) => this.patchState({ studyset })),
      ),
    )(studysetId$);

    this.effect(
      pipe(
        tap((loadingInfo: FlashcardsLoadingInfo) => {
          if (loadingInfo.flashcardsError) {
            this.showErrorMessageAndRedirectToStudyset(loadingInfo.flashcardsError);
          }
          this.patchState({ isLoadingQuestions: loadingInfo.flashcardsLoading });
        }),
      ),
    )(this._flashcardQuizService.loading$);

    const quizData$: Observable<[Flashcard[], Studyset, FlashcardFilterSettings]> = combineLatest([
      this._flashcardQuizService.flashcards$,
      this.studyset$.pipe(take(1)),
      this._flashcardQuizService.currentQuizSettings$.pipe(filterUndefined(), take(1)),
    ]);

    this.effect(
      pipe(
        tap(([flashcards, studyset, filterSettings]: [Flashcard[], Studyset, FlashcardFilterSettings]) => {
          const isIncludingExamType = filterSettings.questionTypes?.includes(FlashcardQuestionType.EXAM_AI) ?? false;
          const isOnlyExamType = isIncludingExamType && !isNil(filterSettings.questionTypes) && filterSettings.questionTypes.length === 1;

          if (!flashcards.length) {
            this.setInitialFlashcards([], isOnlyExamType);
            this._loggerService.debug(`No flashcards to show`);

            return;
          }

          if (isIncludingExamType) {
            this._setAiPoweredQuestions(studyset, flashcards, filterSettings, isOnlyExamType);
          } else {
            this.setInitialFlashcards(flashcards, isOnlyExamType);
          }
        }),
      ),
    )(quizData$);

    this.effect<boolean>(
      pipe(
        distinctUntilChanged(),
        filter(Boolean),
        withLatestFrom(this.state$),
        tap(([_, state]: [boolean, QuizPageState]): void => {
          if (state.showAd) {
            this.goToNextFlashcard();
          }
        }),
      ),
    )(this._adsStatusService.adsDisabled$);
  }

  setSessionFinished(isFinished: boolean): void {
    this.patchState({ isSessionFinished: isFinished });
  }

  setInitialFlashcards(flashcards: Flashcard[], isOnlyExamType: boolean): void {
    // set flashcards and reset other values
    this.patchState({
      flashcards,
      currentFlashcardIndex: 0,
      showResult: false,
      showAd: false,
      showSkipButton: false,
      isCorrectAnswer: false,
      correctAnswersCount: 0,
      isOnlyExamType,
    });
  }

  setCorrectAnswerAndShowResult(isCorrectAnswer: boolean): void {
    this.patchState((state: QuizPageState) => {
      let { correctAnswersCount } = state;

      if (isCorrectAnswer) {
        correctAnswersCount++;
      }

      return {
        isCorrectAnswer,
        showResult: true,
        correctAnswersCount,
      };
    });
  }

  setCustomTotalCount(totalCount: number): void {
    this.patchState({ customTotalCount: totalCount });
  }

  skipReview(): void {
    this.patchState((state: QuizPageState) => ({
      reviewSkippedCount: state.reviewSkippedCount + 1,
      isLoadingReview: false,
      isCorrectAnswer: false,
      showSkipButton: false,
    }));
    this._examIntegrationStore.incrementIndex();
  }

  setExamReviewError(scoreResponseMessage?: string): void {
    if (!this.get().isReviewError) {
      this._sentryService.reportToSentry(
        'WARNING: Score for exam review could not be extracted from gpt response: ' + scoreResponseMessage,
      );
      this.patchState((state: QuizPageState) => ({
        reviewErrorCount: state.reviewErrorCount + 1,
        isReviewError: true,
      }));
    }
  }

  showErrorMessageAndRedirectToStudyset(error: unknown): void {
    if (this._hasTechnicalError || this.get().isSessionFinished) {
      return;
    }

    this._hasTechnicalError = true;
    const errorTranslationKey: string = NotEnoughContentForExamError.isInstance(error)
      ? 'SMART_EXAM.ERROR_NOT_ENOUGH_TEXT_CONTENT'
      : 'SMART_EXAM.ERROR_MESSAGE';

    const messageForSentry: string =
      NotEnoughContentForExamError.isInstance(error) || NotEnoughQuestionsForExamError.isInstance(error) ? error.message : 'Unknown error';

    this._sentryService.reportToSentry(
      'WARNING: Error while getting AI questions for Smart Exam (AI only). User will be redirected back to studyset. ' + messageForSentry,
    );

    this._simpleDialogService
      .scheduleError(this._translationService.get(errorTranslationKey), { allowBackdropDismiss: false })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        void this._navigationService.pop({ studysetId: this.get().studyset?.id });
      });
  }

  trackQuizSessionQuit(): void {
    const {
      isSessionFinished,
      currentFlashcardIndex,
      flashcards,
      correctAnswersCount,
      isLoadingQuestions,
      isLoadingCurrentQuestion,
      isLoadingReview,
      reviewSkippedCount,
      reviewErrorCount,
    } = this.get();

    if (isSessionFinished) {
      return;
    }

    const totalCount: number = flashcards.length;
    const showLoadingScreen: boolean = isLoadingQuestions || isLoadingCurrentQuestion;

    this._quizTrackingService.trackQuizSessionQuit({
      questionsShown: currentFlashcardIndex + 1,
      complete: false,
      questionsTotal: totalCount,
      correctPercentage: Math.round((correctAnswersCount / totalCount) * 100),
      didQuitWhileLoading: showLoadingScreen || isLoadingReview,
      reviewsSkipped: reviewSkippedCount,
      reviewsWithErrors: reviewErrorCount,
    });
  }

  reset(): void {
    this.setState((state: QuizPageState) => {
      return {
        ...initialState,
        studyset: state.studyset,
      };
    });
  }

  private _setAiPoweredQuestions(
    studyset: Studyset,
    flashcards: Flashcard[],
    filterSettings: FlashcardFilterSettings,
    isOnlyExamType: boolean,
  ): void {
    const targetCount = filterSettings.quantity ?? flashcards.length;

    try {
      const updatedFlashcards = this._examIntegrationStore.setupExamQuestionCandidatesAndReturnAdaptedFlashcardList(
        studyset,
        flashcards,
        targetCount,
        isOnlyExamType,
      );
      this.setInitialFlashcards(updatedFlashcards, isOnlyExamType);
    } catch (error: unknown) {
      if (isOnlyExamType) {
        this.showErrorMessageAndRedirectToStudyset(error);
      } else {
        this._loggerService.debug('issue while getting exam questions, falling back to regular question types');
        this.setInitialFlashcards(flashcards, isOnlyExamType);
      }
    }
  }

  private _waitForQuestionToLoad(flashcardId: number): Observable<boolean> {
    return this._examIntegrationStore.questionIsReady$(flashcardId).pipe(
      tap((isReady: boolean) => {
        this.patchState({
          isLoadingCurrentQuestion: !isReady,
        });
      }),
      filter((isReady: boolean) => isReady),
      take(1),
      catchError(() => {
        this._loggerService.warn('Error while waiting for question to load');

        return of(true);
      }),
      finalize(() => {
        this.patchState({
          isLoadingCurrentQuestion: false,
        });
      }),
    );
  }
}
